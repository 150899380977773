// bestia.js

var scrollTop = window.scrollY,
    menu = document.getElementById("menu"),
    toggleMenuBtn = document.getElementById("toggle-menu"),
    logo = document.getElementById("logo-big"),
    logoSmall = document.getElementById("logo-small"),
    isMobile = window.innerWidth<1024?true:false,
    isTouch = null,
    mediaPortrait = window.matchMedia("(orientation: portrait)"),
    isPortrait = null,
    xDown = null,
    yDown = null,
    lastSwipe = null,
    galleryLinks = document.querySelectorAll(".gallery > a"),
    galleryModal = document.getElementById("gallery-modal"),
    galleryZoom = document.getElementById("gallery-zoom"),
    lastGalleryLink = galleryLinks[galleryLinks.length- 2], // remove easteregg
    galleryIndex = 0,
    contact = document.querySelector('.contact #spam-protected-email'),
    rien;

// vh css unit for mobiles
setVh();

// If there are matches, we're in portrait
if(mediaPortrait.matches) {
    isPortrait = true;
} else {
    isPortrait = false;
}
isTouch = tryTouch();

console.log(contact);
contact.outerHTML = '<address property="email">' + unescape('%3c%61%20%68%72%65%66%3d%22%6d%61%69%6c%74%6f%3a%62%65%73%74%69%61%40%67%6d%61%69%6c%2e%63%6f%6d%22%3e%62%65%73%74%69%61%40%67%6d%61%69%6c%2e%63%6f%6d%3c%2f%61%3e') + '</address>';

// console.log(document.body.clientHeight, window.innerHeight);

/*** FUNCTIONS ***/

// VH CSS unit for mobiles
function setVh () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

function tryTouch() {
  try{ document.createEvent("TouchEvent"); return true; }
  catch(e){ return false; }
}

function loadImage (newSrc) {
  var img = new Image();
  img.addEventListener('load', function() {
    // execute drawImage statements here
  }, false);
  img.src = newSrc;
}


// SCROLL ACTIONS
function scrollActions () {

    // hide menu on scrolling down
    if (scrollTop > 0 && scrollTop < window.scrollY) {
      menu.classList.add("hidden");
    } else {
      menu.classList.remove("hidden");
    }

    // save top
    scrollTop = window.scrollY;

    // pin logo to window
    if (scrollTop > window.innerHeight && scrollTop < (document.body.clientHeight - (window.innerHeight*2))) {
      logoSmall.classList.add("active");
    } else {
      logoSmall.classList.remove("active");
    }

}

function toggleMenu() {
  menu.classList.toggle("active");
  easterMobile();
}

function handleTouchStart(e) {
    xDown = e.touches[0].clientX;
    yDown = e.touches[0].clientY;
}

function handleTouchMove(e) {
    if ( ! xDown || ! yDown ) {
        return;
    }
    var xUp = e.touches[0].clientX;
    var yUp = e.touches[0].clientY;
    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/
        if ( xDiff > 0 ) {
          toggleMenu();
        } else {
          toggleMenu();
        }
    } else {
        if ( yDiff > 0 ) {
          // up
        } else {
          // down
        }
    }
    /* reset values */
    xDown = null;
    yDown = null;
}

function openGalleryModal (e) {
  e.preventDefault();
  // change image index
  var idArray = e.currentTarget.id.split('-');
  galleryIndex = idArray[1];

  // show/update modal
  galleryModal.classList.toggle("active");
  galleryZoom.src = this.getAttribute('href');
}
function closeGalleryModal (e) {
  galleryModal.classList.remove("active");
  galleryZoom.src = '';
}

var easterCount = 0;
function easterMobile () {
  if (!tooLate(1000)) {
    easterCount++;
  } else {
    easterCount = 0;
  }
  if(easterCount > 5) {
    document.querySelector('#easter > a').click();
    easterCount = 0;
  }
}

var lastKeyTime = Date.now();
var currentTime = Date.now();

function tooLate (n) {
  currentTime = Date.now();
  var late = (currentTime - lastKeyTime > n) ? true : false;
  lastKeyTime = currentTime;
  return late;
}

function updateGalleryImage (direction) {
  console.log(lastGalleryLink);
  var nextId = 'image-' + galleryIndex;
  var nextLink = document.getElementById(nextId);
  if (nextLink == null) {
    if (direction == 'right') {
      galleryIndex = 1;
      nextLink = document.getElementById('image-1');
    } else {
      galleryIndex = galleryLinks.length-1;
      nextLink = lastGalleryLink;
    }
  }
  var nextHref = nextLink.href;
  galleryZoom.src = nextHref;
}

/*** EVENTS ***/

  // toggles menu on click
  toggleMenuBtn.onclick = toggleMenu;
  menu.onclick = toggleMenu;

  // TOUCH
  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchmove', handleTouchMove, false);

if (!isMobile) {
// TABLETS AND DESKTOP

  if (!isTouch) {
    // RESIZE
    window.addEventListener('resize', () => {
      setVh();
    });

    // handles gallery Zoom
    galleryLinks.forEach((a) => {
      a.addEventListener('click', openGalleryModal, true);
    });
    // close modal
    galleryModal.onclick = closeGalleryModal;
  }

  // SCROLL
  window.addEventListener('scroll', () => {
    scrollActions();
  });

}

// ORIENTATION
window.addEventListener("orientationchange", () => {
  setVh();
});

// MEDIA QUERY
mediaPortrait.addListener(function(m) {
  if(mediaPortrait.matches) {
      isPortrait = true;
  } else {
      isPortrait = false;
  }
  setVh();
});

// keydown detection
var word = "fleur";
var input = "";
var charList = 'abcdefghijklmnopqrstuvwxyz';
document.body.addEventListener('keydown',function(e){

  // Gallery nav
  if (e.keyCode == '37') {
    galleryIndex--;
    updateGalleryImage ('left')
  }
  else if (e.keyCode == '39') {
    galleryIndex++;
    updateGalleryImage ('right')
  }

  // EASTER EGG
  else {
    var key = e.key.toLowerCase();
    if (charList.indexOf(key) === -1) return;
    if (tooLate(1000)) {
        input = "";
    }
    input += key;
    // console.log(input);
    if(input == word){
        // alert('working!');
        closeGalleryModal();
        document.querySelector('#easter > a').click();
        input = "";
    }
  }
});
// document.addEventListener('DOMContentLoaded', () => {
//     'use strict';
//
//     let buffer = [];
//     let lastKeyTime = Date.now();
//
//     document.addEventListener('keydown', event => {
//         const charList = 'abcdefghijklmnopqrstuvwxyz';
//         const key = event.key.toLowerCase();
//
//         // we are only interested in alphanumeric keys
//         if (charList.indexOf(key) === -1) return;
//
//         const currentTime = Date.now();
//
//         if (currentTime - lastKeyTime > 1000) {
//             buffer = [];
//         }
//
//         buffer.push(key);
//         lastKeyTime = currentTime;
//
//         console.log(buffer);
//     });
// });
